<template>
  <div>
    <b-modal
      id="edit-saturday-shift"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="$t('EditSaturdayShift')"
      hide-footer
    >
      <div style="display: flex; width: 100%; justify-content: space-between; margin-bottom: 3%;">
        <b-form-group
          id="input-group-4"
          :label="$t('StartDate')"
          style="width: 95%;"
          label-for="input-9"
        >
          <date-picker
            v-model="time1"
            type="date"
            value-type="format"
            :format="'DD-MM-YYYY'"
            style="width: 95%;"
          />
        </b-form-group>

        <b-form-group
          id="input-group-4"
          :label="$t('EndDate')"
          style="width: 90%;"
          label-for="input-9"
        >
          <date-picker
            v-model="time2"
            type="date"
            style="width: 100%;"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'DD-MM-YYYY'"
          />
        </b-form-group>
      </div>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("VacationSubmit") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="
            margin-right: 15px;
            background: white;
            color: black;
            border-color: white;
            font-weight: 400;
          "
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {
  components: {
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['editProp'],
  data() {
    return {
      yearsArray: [2023, 2024, 2025, 2026],
      modalTriggeredByToggle: false,
      time1: null,
      time2: null,
      form: {
        startDate: null,
        endDate: null,
        id: null,
      },
    }
  },
  watch: {
    'editProp.startDate': {
      handler(value) {
        const temp = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
        this.time1 = temp
        // this.time2 = value.endDate
      },
    },
    'editProp.endDate': {
      handler(value) {
        const temp = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
        this.time2 = temp
      },
    },
    'editProp.id': {
      handler(value) {
        this.form.id = value
        // this.time2 = value.endDate
      },
    },
    time1(value) {
      const time = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.form.startDate = time
    },
    time2(value) {
      const time = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.form.endDate = time
    },
    // time1(value) {
    //   const res = moment.tz.guess();
    //   const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
    //   this.form.startDate = moment
    //     .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
    //     .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // },
    // time2(value) {
    //   const res = moment.tz.guess();
    //   const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
    //   this.form.endDate = moment
    //     .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
    //     .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // },
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      this.$emit('editSaturday', this.form)
      this.$refs.modal.hide()
    },
    onCancel() {
      if (!this.modalTriggeredByToggle) {
        // Only update the state if the modal was not triggered by the toggle button
        this.loadSaturdayWork();
      }
      this.$emit('saturdayy', this.saturday)

      this.modalTriggeredByToggle = false; // Reset the flag
      this.$refs.modal.hide();
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.deactivate-modal__select {
  display: flex;
  flex-direction: column;
  select {
    margin-bottom: 10px;
    padding: 10px;
  }
  button {
    align-self: end;
  }
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.my-class {
  background: black;
  font-weight: bold;
}

// .my-second-class > .modal-dialog > .modal-content > .modal-header {
// background: black;
// color: white;
// }
</style>
