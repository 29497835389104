<template>
  <div>
    <b-modal
      id="saturday-configure"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="$t('ConfigSaturday')"
      hide-footer
    >

      <b-form>
        <div style="display: flex; width: 100%; justify-content: space-between;">
          <b-form-group
            id="input-group-4"
            :label="$t('StartDate')"
            label-for="input-9"
          >
            <!-- <b-form-input
            id="input-4"
            v-model="form.startDate"
            type="date"
          /> -->
            <date-picker
              v-model="time1"
              type="date"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'DD-MM-YYYY'"
            />
            <span
              v-show="formSubmitted && !$v.time1.required"
              style="color: red"
            >{{ $t('PlsSelectD') }}</span>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            :label="$t('EndDate')"
            label-for="input-9"
          >
            <!-- <b-form-input
            id="input-4"
            v-model="form.endDate"
            type="date"
          /> -->
            <date-picker
              v-model="time2"
              type="date"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'DD-MM-YYYY'"
            />
            <span
              v-show="formSubmitted && !$v.time2.required"
              style="color: red"
            >{{ $t("PlsSelectD") }}</span>
          </b-form-group>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px; background: #FF274F !important;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {
  components: {
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      yearsArray: [2023, 2024, 2025, 2026],
      modalTriggeredByToggle: false,
      form: {
        startDate: null,
        endDate: null,
      },
      time1: null,
      time2: null,
      formSubmitted: false,
    }
  },
  watch: {
    time1(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.startDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.endDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
  },
  methods: {
    ...mapActions(['loadSaturdayWork']),
    onSubmit() {
      this.$emit('addSaturday', this.form)
      this.$refs.modal.hide()
    },
    onCancel() {
      if (!this.modalTriggeredByToggle) {
        // Only update the state if the modal was not triggered by the toggle button
        this.loadSaturdayWork();
      }
      this.$emit('saturdayy', this.saturday)

      this.modalTriggeredByToggle = false; // Reset the flag
      this.$refs.modal.hide();
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
