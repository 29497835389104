<template>
  <div>
    <b-modal
      id="cancel-vacation-request"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('VacationCancelV')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <p>{{ $t('CancelVacation') }}</p>
        <pre />
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            style="background: #FF274F !important;"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            style="margin-right: 15px; background: white; color: black; border-color: white; font-weight: 400;"
            class="buttonCancel"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['vacationId'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),

  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    onSubmit() {
      this.$refs.modal.hide()
      this.$emit('cancel', this.vacationId);
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
