<template>
  <div>
    <b-modal
      id="leave-request"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('LeaveReq')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <b-form-group
          id="input-group-5"
          :label="$t('VacType')"
          label-for="input-2"
        >
          <vue-select
            id="input-5"
            v-model="reason"
            required
            :options="vacationTime"
            :reduce="(e) => e.value"
            label="label"
            :placeholder="$t('SelectVacationType')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.reason.required"
            style="color: red"
          >{{ $t("Reason") }}</span>
        </b-form-group>
        <b-form-group
          v-if="showInput"
          id="input-group-8"
          :label="$t('OtherReason')"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model="form.vacationType"
            style="height: 80px"
          />
        </b-form-group>
      </b-form>
      <b-form-group
        :label="$t('Notes')"
      >

        <b-form-textarea
          id="input-15"
          v-model="form.notes"
        />

      </b-form-group>
      <b-form>
        <b-form-group
          id="input-group-4"
          :label="$t('StartDate')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="form.startDate"
            type="date"
          /> -->
          <date-picker
            v-model="time1"
            type="date"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'DD-MM-YYYY'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time1.required"
            style="color: red"
          >{{ $t('PlsSelectD') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="$t('EndDate')"
          label-for="input-9"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="form.endDate"
            type="date"
          /> -->
          <date-picker
            v-model="time2"
            type="date"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'DD-MM-YYYY'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time2.required"
            style="color: red"
          >{{ $t("PlsSelectD") }}</span>
        </b-form-group>
      </b-form>
      <span
        v-if="getHasVacation == true"
        style="color: red"
      >{{ $t('ExistinLeaveReq') }}</span>
      <br>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          :disabled="getHasVacation == true"
          @click="onSubmitRequestVacation"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
            background: white;
            color: black;
            border-color: white;
            font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

import { required } from 'vuelidate/lib/validators';
import { client } from '../../../domainConfig'


export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedId', 'userObj', 'date'],
  data() {
    return {
      resettingForm: Boolean,
      replace: false,
      manager: '',
      user: {},
      client: client.clientName,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      substituteEmployee: null,
      selectedItemId: null,
      sixmonth: false,
      reason: '',
      showInput: false,
      time1: null,
      formSubmitted: false,
      time2: null,
      hasVacation: false,
      isPhoneNumberValid: null,
      form: {
        employeeUserId: this.selectedId,
        startDate: null,
        endDate: null,
        vacationType: '',
      },
      form2: {
        managerId: this.selectedId,
        coManagerId: '',
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
    };
  },
  validations: {
    time1: {
      required,
    },
    time2: {
      required,
    },
    reason: {
      required,
    },
  },

  computed: {
    ...mapGetters([
      'getfreeEmployees',
      'getLoggedInUser',
      'getFiliales',
      'getFreeManagers',
      'getHasVacation',
    ]),
    vacation1() {
      return [
        { value: 'Vacation', label: this.$t('Vacation') },
        { value: 'SickLeave', label: this.$t('AddNew19') },
        { value: 'SpecialLeave', label: this.$t('AddNew22') },
        { value: 'Appointment', label: this.$t('AddNew24') },
        { value: 'AccidentLeave', label: this.$t('AddNew20') },
        { value: 'Education', label: this.$t('AddNew21') },
        { value: 'Maternity', label: this.$t('AddNew23') },
      ]
    },
    vacation2() {
      return [
        { value: 'SickLeave', label: this.$t('AddNew19') },
        { value: 'SpecialLeave', label: this.$t('AddNew22') },
        { value: 'Appointment', label: this.$t('AddNew24') },
        { value: 'AccidentLeave', label: this.$t('AddNew20') },
        { value: 'Education', label: this.$t('AddNew21') },
        { value: 'Maternity', label: this.$t('AddNew23') },
      ]
    },
    vacationTime() {
      if (this.client == 'DrenushaXharra') {
        if (this.sixmonth == true) {
          return this.vacation1
        }
        return this.vacation2
      }
      return this.vacation1
    },
    filteredManagers() {
      return this.getFreeManagers.filter((manager) => {
        return manager.userId !== this.userObj.userId;
      });
    },
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = new Date(this.time1.split('-').reverse().join('-')).getTime();
      const end = new Date(this.time2.split('-').reverse().join('-')).getTime();
      return start <= end;
    },
  },
  watch: {
    reason(value) {
      if (value == 'Other') {
        this.showInput = true;
        this.form.vacationType = '';
      } else {
        this.showInput = false;
        this.form.vacationType = value;
      }
    },
    date(value) {
      const specificDate = moment(value);
      const today = moment();
      const differenceInDays = today.diff(specificDate, 'days');
      this.sixmonth = differenceInDays > 180;
      console.log('difference ', this.sixmonth);
    },

    time1(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.startDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.endDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    // eslint-disable-next-line func-names
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
      }
    },
    // eslint-disable-next-line func-names
    'form.startDate': function (value) {
      if (!this.resettingForm && this.form.endDate != null) {
        this.getAlreadyRequestedVacation({
          employeeUserId: this.form.employeeUserId,
          startDate: value,
          endDate: this.form.endDate,
        });
      }
      this.resettingForm = false;
    },
    // eslint-disable-next-line func-names
    'form.endDate': function (value) {
      this.getAlreadyRequestedVacation({
        employeeUserId: this.form.employeeUserId,
        startDate: this.form.startDate,
        endDate: value,
      });
    },
    // eslint-disable-next-line func-names
    'form.vacationType': function (newValue) {
      this.form.vacationType = newValue.replace(/\s+/g, '');
    },
    selectedId: {
      handler(value) {
        this.form.employeeUserId = value;
        this.form2.managerId = value;
      },
      immediate: true,
    },
    manager: {
      handler(value) {
        this.form2.coManagerId = value.userId;
      },
      immediate: true,
    },
  },
  created() {
    // console.log('sadafs', this.user);
  },
  methods: {
    ...mapActions([
      'getAlreadyRequestedVacation',
      'releaseAndReplaceEmployeeFromTour',
      'addCoManagerToStores',
      'freeEmployee',
      'releaseEmployeeFromTour',
    ]),

    handleModalShown() {
      // Call your method here
      this.form.employeeUserId = this.selectedId;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitRequestVacation() {
      this.formSubmitted = true;
      this.$v.reason.$touch();
      if (this.$v.reason.$anyError) {
        return;
      }
      this.$v.time1.$touch();
      if (this.$v.time1.$anyError) {
        return;
      }
      this.$v.time2.$touch();
      if (this.$v.time2.$anyError) {
        return;
      }

      this.$emit('pushFormUser', this.form);
      if (this.userObj.personRole == 'Manager') {
        await this.addCoManagerToStores(this.form2);
      }
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.resettingForm = true;
      this.selectedItemId = null;
      this.reason = '';
      this.form.employeeUserId = '';
      this.form.startDate = null;
      this.form.endDate = null;
      this.form.vacationType = '';
      this.form.notes = '';
      this.time1 = '';
      this.time2 = '';
      this.notes = ''
      this.formSubmitted = false;
      this.form2.coManagerId = null;
      this.manager = null;
      this.$store.commit('SET_ALREADY_REQUESTED_VACATION', false);
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.vacation {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr;
}
.form2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
// .mx-datepicker-popup {
//     position: absolute !important;
//     margin-top: 1px;
//     margin-bottom: 1px;
//     box-shadow: none;
//     top: 260px !important;
//     left: 175px !important;
//     border: 0px solid black !important;
// }
</style>
