<template>
  <div
    style="margin-top: 0px"

    @click="removeLists"
  >
    <div>
      <b-tabs
        v-model="tabIndex"
      >
        <b-tab
          :title="$t('Vacation')"
          @click="sendFirstTabIndex"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Vacation") }}</strong>
          </template>
          <div
            class="table__header"
            :style="
              isactive == true
                ? 'justify-content: space-between; margin-bottom: 15px;'
                : 'justify-content: flex-end; margin-bottom: 15px'
            "
          >
            <div>
              <div
                style="display: flex"
                class="con"
              >
                <div>
                  <div class="black">
                    <b-icon-search
                      v-if="searchUser == ''"
                      class="search1"
                      style="position: absolute; left: 5px; top: 12px"
                    />
                    <b-icon-x-circle
                      v-else-if="searchUser != '' && !noSearch"
                      class="search1"
                      style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                      @click="searchUser = ''"
                    />
                    <input
                      v-model="searchUser"
                      autocomplete="off"
                      type="text"
                      name="search-box"
                      :placeholder="$t('FullName')"
                      :style="
                        getFilteredActiveUsers.length > 0 ||
                          (getFilteredInactiveUsers.length > 0 && noSearch)
                          ? 'border-radius: 8px 8px 0px 0px'
                          : ''
                      "
                      @keyup="inputChanged"
                      @keydown.down="onArrowDown"
                      @keydown.up="onArrowUp"
                    >
                  </div>
                  <div
                    v-if="
                      (getFilteredActiveUsers.length > 0 || getFilteredInactiveUsers.length > 0) &&
                        noSearch
                    "
                    ref="scrollContainer"
                    class="dropdrop"
                  >
                    <div
                      v-for="(result, index) in getFilteredActiveUsers.length > 0
                        ? getFilteredActiveUsers
                        : getFilteredInactiveUsers"
                      :key="index"
                      ref="options"
                      style="cursor: pointer"
                      class="search-item"
                      :class="{ 'is-active': index === arrowCounter }"
                      @click="
                        searchByOn(result);
                        noSearch = false;
                      "
                    >
                      <p style="margin: 0">
                        {{ result.fullName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-modal
              ref="modal"
              v-model="showModal"
              title="Confirmation"
              header-class="headerModal"
              centered
              hide-footer
            >
              <div>{{ $t('SaturdayConfig') }}</div>

              <div class="buttonsEverywhere">

                <b-button
                  type="button"
                  variant="primary"
                  class="buttonSubmit"
                  style="background:linear-gradient(52deg, #262e6c 0%, #9b2a5c 100%, #ff274f 100%) 0% 0% no-repeat padding-box;
                      margin-top: 10px;"
                  @click="onSubmit"
                >
                  {{ $t("Submit") }}
                </b-button>

                <b-button
                  type="button"
                  variant="danger"
                  class="buttonCancel"
                  style="
                      margin-right: 15px;
                      margin-top: 10px;
                      background: white;
                      color: black;
                      border-color: white;
                      font-weight: 400;"
                  @click="onCancel"
                >
                  {{ $t("Cancel") }}
                </b-button>

              </div>
            </b-modal>
          </div>
          <div
            style="width: 100%; border-top: 1px solid lightgray; padding-top: 16px;overflow-y: scroll;"
            class="scroll"
          >
            <table
              class="team_table"
            >
              <thead style="position: sticky; top: -20px">
                <tr>
                  <th>
                    {{ $t('No') }}
                  </th>
                  <th>
                    {{ $t('Salutation') }}
                  </th>
                  <th>
                    {{ $t('FirstName') }}
                  </th>
                  <th>
                    {{ $t('LastName') }}
                  </th>
                  <th style="width: 7%">
                    {{ $t('DateofBirth') }}
                  </th>
                  <th style="width: 8%">
                    {{ $t('PhoneNumber') }}
                  </th>
                  <th>
                    {{ $t('Email') }}
                  </th>
                  <!-- <th
                      style="padding: 10px; text-align: left;background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255) !important;
                      color: rgb(38, 46, 108) !important;
                      font-size: 15px;"
                    >
                      {{ $t('Street') }},  {{ $t('Door/HouseNumber') }} / {{ $t('ZipCode') }}, {{ $t('City') }} / {{ $t('Country') }}
                    </th> -->
                  <th>
                    {{ $t('Role') }}
                  </th>
                  <th>
                    {{ $t("VacationDays") }}
                  </th>
                  <th>
                    {{ $t('Created') }}
                  </th>
                  <th>
                    {{ $t("EditVac") }}
                  </th>
                  <th>
                    {{ $t("LeaveReq") }}
                  </th>
                  <!-- <th
                style="padding: 10px; text-align: left;background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255) !important;
                color: rgb(38, 46, 108) !important;
                font-size: 15px;"
              >
                Edit vacation days
              </th> -->
                  <!-- <th
                style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255) !important;
                color: rgb(38, 46, 108) !important;
                font-size: 15px;"
              >
                Leave Request
              </th> -->
                </tr>
              </thead>

              <tbody v-if="isactive == true">
                <tr
                  v-for="(user, index) in filteredUsers"
                  :key="index"
                  v-b-tooltip.hover.bottom
                  class="hover-row"
                  style="cursor: pointer;"
                  :title="getLanguage === 'en' ? `${$t('ClickFor')} ${user.firstName} ${user.lastName} ${$t('DaysOff')} ${$t('Details')}` : `${$t('ClickForDetails')} ${$t('DaysOff')} ${user.firstName} ${user.lastName}`"
                  @click="matProps(user), showSameId(user.userId), loadVacation(user.userId)"
                >
                  <!-- <td style="padding: 9px;font-weight: bold; color: black;">
                      {{ getTotalItemsForUsers-(page-1) * pageSize-index }}.
                    </td> -->
                  <td>
                    {{ getTotalItemsForUsers-(page-1) * pageSize-index }}.
                  </td>
                  <td>
                    {{ $t(user.salutation) }}
                  </td>
                  <td>
                    {{ user.firstName }}
                  </td>
                  <td>
                    {{ user.lastName }}
                  </td>
                  <!-- <td
                    <td
                      style="padding: 9px;font-weight: bold;
                      color: black;"
                    >
                      {{ user.gender }}
                    </td> -->
                  <td>
                    {{ dateModified(user.dateOfBirth) }}
                  </td>
                  <td>
                    +{{ countryCallingCode[user.countryCode] }} {{ user.phoneNumber }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <!-- <td
                      style="padding: 9px;font-weight: bold;
                      color: black;"
                    >
                      {{ user.doorNumber }}, {{ user.street }} / {{ user.postalCode }}, {{ user.city }} / {{ user.country }}
                    </td> -->
                  <td>
                    <button id="button">
                      {{ $t(user.role) }}
                    </button>
                  </td>
                  <td>
                    {{ user.totalDays }} / {{ user.remainingDays }}
                  </td>
                  <td>
                    {{ dateModified(user.created) }}
                  </td>
                  <td>
                    <b-button
                      v-b-modal.add-edit-vacation-days
                      class="editButton"
                      @click="saveUser(user), loadRemainingDays(user.userId)"
                    >{{ $t("Edit") }}</b-button>
                  </td>
                  <td>
                    <b-button
                      v-b-modal.leave-request
                      class="editButton"
                      @click="saveUser(user)"
                    >{{ $t("Request") }}</b-button>
                  </td>
                </tr>
                <div style="margin: 10px">
                  <b-button
                    v-if="selectedId != null"
                    class="button view"
                    @click="showAll"
                  >
                    {{ $t("ViewAll") }}
                  </b-button>
                </div>
              </tbody>
            </table>
          </div>
          <div>
            <b-pagination
              v-if="isactive == true && getTotalItemsForUsers > 15"
              v-model="page"
              :total-rows="getTotalItemsForUsers"
              :per-page="pageSize"
              first-number
              last-number
              :hidden="selectedId"
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span>{{ $t("Previous") }}</span>
              </template>
              <template #next-text>
                <span>{{ $t("Next") }}</span>
              </template>
            </b-pagination>
            <b-pagination
              v-else-if="isactive == false && getTotalItemsForInActiveUsers > 15"
              v-model="page"
              :total-rows="getTotalItemsForInActiveUsers"
              :per-page="pageSize"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span>{{ $t("Previous") }}</span>
              </template>
              <template #next-text>
                <span>{{ $t("Next") }}</span>
              </template>
            </b-pagination>
          </div>
        </b-tab>
        <b-tab
          :title="$t('SaturdayShift')"
          @click="sendSecondTabIndex"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("SaturdayShift") }}</strong>
          </template>
          <div style="display: flex; justify-content: flex-end;">
            <button
              v-b-modal.saturday-configure
              class="button"
              style="margin-right: 25px; margin-bottom: 10px;"
            >
              {{ $t('SaturdayShift') }}
            </button>
          </div>
          <div
            style="width: 100%; border-top: 1px solid lightgray; padding-top: 16px;"
            class="scroll"
          >
            <table
              class="team_table saturday"
            >
              <thead>
                <tr style="text-align: center">
                  <th>
                    {{ $t('StartDate') }}
                  </th>

                  <th>
                    {{ $t('EndDate') }}
                  </th>

                  <th>
                    {{ $t('Edit') }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="item in getSaturdayWork"
                :key="item.id"
              >
                <tr>
                  <td style="text-align: center;">
                    {{ dateModified(item.startDate) }}
                  </td>
                  <td style="text-align: center;">
                    {{ dateModified(item.endDate) }}
                  </td>
                  <td
                    v-b-modal.edit-saturday-shift
                    style="text-align: center;"
                    @click="editSaturday(item)"
                  >
                    <p class="p-0 m-0 ml-50">
                      <b-icon-pencil />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <LeaveRequestEmployeeModal
      :user-obj="userObj"
      :selected-id="selectedId"
      :date="createdDate"
      @pushFormUser="leaveRequests"
    />
    <AddEditVacationDays
      :page="page"
      :user-obj="userObj"
      :selected-id="selectedId"
      :year-change="yearChange"
    />
    <SaturdayShift
      @Confirm="changeSaturdayWork"
      @saturdayy="saturdayWorkStatuss"
      @addSaturday="addSaturdayShift"
    />
    <EditSaturdayShift
      :edit-prop="form"
      @editSaturday="editSaturdayShift"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import LeaveRequestEmployeeModal from '@/components/teams/modals/LeaveRequestEmployeeModal.vue';
import AddEditVacationDays from '@/components/teams/modals/AddEditVacationDays.vue';
import { countryCallingCodes } from '../../countryCodes'
import { client } from '../../domainConfig';
import SaturdayShift from './modals/SaturdayShift.vue'
import EditSaturdayShift from './modals/EditSaturdayShift.vue'



export default {
  components: {
    LeaveRequestEmployeeModal,
    AddEditVacationDays,
    SaturdayShift,
    EditSaturdayShift,
  },
  props: {
    users: {
      type: String,
      default: '',
    },
    yearChange: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countryCallingCode: countryCallingCodes,
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      saturdayWorkStatus: false,
      client: client.clientName,
      showModal: false,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      form: {},
      editUser: {
        userId: '',
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        country: '',
        email: '',
        role: '',
        resetPassword: false,
      },
      tabIndex: 0,
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      createdDate: null,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
      'getUsers',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getSaturdayWork',
    ]),
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getUsers.filter((getUsers) => {
          return getUsers.userId === this.selectedId;
        })
        : this.getUsers;
    },
  },
  watch: {
    page(value) {
      if (this.isactive) {
        this.getUsersPagination({
          status: true,
          pageNumber: value,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        });
      } else {
        this.getInactiveUsersPagination({
          status: false,
          pageNumber: value,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        });
      }
    },
    searchUser(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.selectedId = null;
        if (value == '') {
          this.removeLists();
          if (this.isactive) {
            this.getUsersPagination({
              status: true,
              pageNumber: this.page,
              pageSize: this.pageSize,
              fullName: value,
            });
          } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
            return;
          } else {
            this.getInactiveUsersPagination({
              status: false,
              pageNumber: this.page,
              pageSize: this.pageSize,
              fullName: value,
            });
          }
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else if (this.isactive) {
          this.filterActiveUsersByName(value);
        } else {
          this.filterInactiveUsersByName(value);
        }
        this.noSearch = true;
      }, 500)
    },
    getSaturdayWork(value) {
      this.saturdayWorkStatus = value.isActive;
    },
  },
  mounted() {
    this.fetch();
    this.loadSaturdayWork();
  },
  methods: {
    ...mapActions([
      'getUsersPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'remainingDaysOfEmployee',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'loadSaturdayWork',
      'editSaturdayWork',
      'addSaturdayWork',
    ]),
    sendFirstTabIndex() {
      this.tabIndex = 0
      this.$emit('updateFirstTab', this.tabIndex);
    },
    sendSecondTabIndex() {
      this.tabIndex = 1
      this.$emit('updateSecondTab', this.tabIndex);
    },
    saturdayWorkStatuss(value) {
      this.saturdayWorkStatus = !value
    },
    addSaturdayShift(value) {
      this.addSaturdayWork({
        object: value,
        successCallback: () => {
          this.loadSaturdayWork();
          this.tabIndex = 1
        },
      })
    },
    editSaturday(value1) {
      this.form = value1
    },
    editSaturdayShift(value) {
      console.log(value)
      this.editSaturdayWork({
        id: value.id,
        object: { startDate: value.startDate, endDate: value.endDate },
        successCallback: () => {
          this.loadSaturdayWork();
          this.tabIndex = 1
        },
      })
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.getUsersPagination({
          status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: this.searchUser,
        }).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    changeSaturdayWork(value) {
      this.editSaturdayWork({
        object: {
          clientName: this.client,
          isActive: this.saturdayWorkStatus ? value : !value,
        },
      });
    },
    loadRemainingDays(value) {
      this.remainingDaysOfEmployee({ employeeUserId: value, year: moment().format('YYYY') });
    },
    async leaveRequests(obj) {
      await this.leaveRequest({
        object: obj,
        successCallback: () => {
          this.loadVacationRequests({ employeeId: this.selectedId, year: this.yearChange });
          this.getUsersPagination({
            status: true,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: null,
          })
        },
      });
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    saveEmployeeId(id) {
      this.employeeId = id
    },
    onCancel() {
      this.showModal = false; // Set showModal to true to open the modal
    },
    onSubmit() {
      this.addSaturdayWork({
        object: {
          clientName: this.client,
          isActive: true,
        },
        successCallback: () => {
          this.loadSaturdayWork();
        },
      })
      this.showModal = false;
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.getUsersPagination({
            status: this.status,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    openModal() {
      this.showModal = true; // Set showModal to true to open the modal
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadVacation(id) {
      this.loadVacationRequests({ employeeId: id, year: this.yearChange })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    toggleBtn(truee) {
      this.sync = truee;
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    saveUser(value) {
      this.userObj = value
      this.createdDate = value.created
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    async searchByOn(value) {
      this.searchUser = value.fullName;
      this.noSearch = false;
      if (this.isactive) {
        await this.getUsersPagination({
          // status: true,
          pageNumber: this.page,
          pageSize: this.pageSize,
          fullName: this.searchUser,
          // firstName: value.firstName,
          // lastName: value.lastName,
        });
      } else {
        await this.getInactiveUsersPagination({
          status: false,
          pageNumber: this.page,
          pageSize: this.pageSize,
          firstName: value.firstName,
          lastName: value.lastName,
        });
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>

.team_table td {
  padding: 11px 9px !important;
}

.saturday td {
  padding: 16px 9px !important;
}

#button {
  width: fit-content;
  background: #EAEDFB;
  color: #242F6E;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #242F6E;
  border-radius: 1000px;
  cursor: default;
}

.editButton {
  width: 100px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.form-control {
  width: 100%;
  border: 1px solid #242f6e;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(191, 203, 217);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #242f6e;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
/* .button {
  width: fit-content;
} */
.addEmployeeButton {
  width: 210px;
}

.edvc{
  height:34px;
  font-size: small;
  background: #FF274F;
  border: none;
}
.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1646px) {
  .edvc{
    height: 50px !important;
  }
}
@media screen and (max-width: 1400px) {
  .edvc{
    height: 50px !important;
  }
}
@media screen and (max-width: 1110px) {

  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
