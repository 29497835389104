<template>
  <div>
    <section
      class="main no-flex"
      style="margin-top: 13px;"
    >

      <UsersListingLeaveRequest
        :year-change="yearChange"
        @click="handleUser"
        @show-other-component="showOtherComponent = true"
        @show-the-component="showOtherComponent = false"
        @updateFirstTab="handleUpdateFirstTabIndex"
        @updateSecondTab="handleUpdateSecondTabIndex"
      />
      <UsersAttendance
        v-if="!showOtherComponent && tabIndexOfLeaveRequest == 0"
        :user="user"
        @yearOf="changeYear"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import UsersListingLeaveRequest from '@/components/teams/UsersListingLeaveRequest.vue'
import moment from 'moment'
import UsersAttendance from '@/components/teams/UsersAttendance.vue'


export default {
  name: 'Home',
  components: {
    UsersListingLeaveRequest,
    UsersAttendance,
  },
  data() {
    return {
      user: '',
      tabIndexOfLeaveRequest: 0,
      showOtherComponent: false,
      yearChange: moment().format('YYYY'),
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    handleUpdateFirstTabIndex(tabIndex) {
      console.log('value', tabIndex)
      this.tabIndexOfLeaveRequest = tabIndex;
    },
    handleUpdateSecondTabIndex(tabIndex) {
      console.log('value', tabIndex)
      this.tabIndexOfLeaveRequest = tabIndex;
    },
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
    changeYear(year) {
      this.yearChange = year
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1600px){
  .main{
    padding: 75px 15px 15px 100px;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 60px 15px 15px 85px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}

@media screen and (max-width: 1200px){
  .main{
    padding: 75px 15px 15px 10px
  }
}
</style>
